/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authenticatedUser } from '../../redux/actions';

class RestrictedRoute extends Component {

  componentDidMount() {
    this.props.authenticatedUser();
  }

  renderMergedProps = (component, rest) => React.createElement(component, rest);

  render() {
    const { component, authenticated, ...rest } = this.props;
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    return (
      <Route {...rest} render={(props) => (authenticated || AuthToken) ? (
        this.renderMergedProps(component, {...rest, ...props})
      ) : (
        <Redirect to={{
          pathname: '/user',
          state: { from: props.location }
        }} />
      )} />
    )
  }
}

RestrictedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.func.isRequired,
  authenticated: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  authenticatedUser: () => dispatch(authenticatedUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RestrictedRoute));
