import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
//import Datetime from 'react-datetime';

import { StyledModal, StyledBody, StyledHeader, Container } from '../AddWellnessInitiative/styles';
import { imgPath } from '../../utils/constants';
import { SubmitButton, Error, SubmitContainer, ForgetImg , PasswordContent } from '../ForgetPassword/styles';
import { ErrorMessage } from '../LoginPage/styles';
import { SingleTile, SettingsName, StyledInput } from '../../containers/Settings/styles';
//import { InputContainer, DateIconContainer } from '../CreateEvent/styles';
//import { DateIcon } from '../SvgComponents';

import { resetPassword } from '../../redux/actions';
import {LOGO_URL} from '../../utils/config';
class ResetPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      rePassword: '',
      showErrmsg: false,
      errMsg: '',
      passMsg: false,
      checkMail: false,
      dateOfBirth: false,
      birthDate: '',
      showDate: '',
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = (e) => {
    if(this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        showDate: ''
      });
    }
  };

  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };
  
  navigateLink = () => {
    const { history } = this.props;
    history.push('/user');
  };

  validatePassword = (e) => {
    e.preventDefault();
    const { pathname } = this.props.history.location;
    let resetPasswordUrl = pathname.split('/');
    let obj={};
    obj['link_time'] = resetPasswordUrl[4];
    let token = '';
    if(resetPasswordUrl[resetPasswordUrl.length - 1] === '') {
      for( let i=5; i<=resetPasswordUrl.length-3; i++) {
        if(i === resetPasswordUrl.length -3) {
          token = token+resetPasswordUrl[i]
        }
        else {
          token = token+resetPasswordUrl[i]+'/'
        }
      }
    }
    else {
      for( let i=5; i<=resetPasswordUrl.length-2; i++) {
        if(i === resetPasswordUrl.length -2) {
          token = token+resetPasswordUrl[i]
        }
        else {
          token = token+resetPasswordUrl[i]+'/'
        }
      }
    }
    obj['remember_token'] = token;
    obj['password'] = this.state.password.trim();
    //obj['birthday'] = this.state.birthDate;
    const { resetPassword } = this.props;
    resetPassword(obj);
    this.setState({
      showErrmsg: false,
      errMsg: '',
      checkMail: false
    })
    this.navigateLink();
  };

  inputChangePass = (e) => {
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if(e.target.name === 'password') {
      this.setState({
        'password':e.target.value,
        showErrmsg: false,
        errMsg: '',
        passMsg: false
      },() => {
        if(!regex.test(this.state.password)) {
          this.setState({
            passMsg: true,
            checkMail: false
          })
        } else if(this.state.rePassword && (this.state.password !== this.state.rePassword)) {
          this.setState({
            showErrmsg: true,
            errMsg: '* New password and Confirm password should match',
            checkMail: false
          })
        }
        else {
          this.setState({
            checkMail: true
          })
        }
      })
    }
    else {
      this.setState({
        'rePassword': e.target.value,
        showErrmsg: true,
        errMsg: '* New password and Confirm password should match'
      },() => {
        if(this.state.password === this.state.rePassword) {
          if(regex.test(this.state.rePassword)) {
            this.setState({
              checkMail: true,
              showErrmsg: false,
              errMsg: ''
            })
          }
          else {
            this.setState({
              checkMail: false,
              showErrmsg: false,
              passMsg: true
            })
          }
        }
        else {
          this.setState({
            checkMail: false,
            showErrmsg: true,
            errMsg: '* New password and Confirm password should match'
          })
        }
      })
    }
  };

  closeDate = (e) => {
    if(e.target.className === 'form-control') {
      if(this.state.count>0 && e.target.className === 'form-control' && this.state.showDate=='birthDate') {
        this.setState({ showDate: '' })
      }
      else {
        this.setState({  count: 1 })
      }
    }
  }

  showDatePicker = (value) => {
    if(this.state.showDate === value) {
      this.setState({
        showDate: ''
      });
    } else {
      this.setState({
        showDate: value
      });
    }
  };

  changeDate = (e, stateName) => {
    let value = moment();
    value = moment(e._d).format('YYYY-MM-DD');
    this.setState({
      [stateName]: value,
      showDate: '',
      dateOfBirth: true
    });
  };

  render() {
    const { checkMail, passMsg, password, rePassword, showErrmsg, errMsg } = this.state;
    // const yesterday = Datetime.moment().subtract(18, 'years');
    // const valid = (currentDate) => currentDate.isBefore(yesterday);
    return(
      <StyledModal show={true} onHide={() => this.navigateLink()}>
        <StyledHeader closeButton>
          <Container>
            <ForgetImg>
              <img src={imgPath+`${LOGO_URL ? '/Logo/woliba_trans.png' : '/training-amigo-logo.png'}`} 
                alt="training-amigo-logo"
                onClick={() => this.props.history.push('/')}
              />
            </ForgetImg>
          </Container>
        </StyledHeader>
        <StyledBody>
          <form onSubmit={(e) => this.validatePassword(e)}>
            <PasswordContent>
              Reset Your Password
            </PasswordContent>           
            <SingleTile width="100">
              <SettingsName>
                New Password <span className="important">*</span>
              </SettingsName>
              <StyledInput type="password" placeholder="New Password" name="password" value={password} onChange={(e) => this.inputChangePass(e)}/>
            </SingleTile>
            { passMsg 
              &&
              <Error>
                <ErrorMessage>* Must be at least 8 characters and contain one uppercase, lowercase, and number</ErrorMessage> 
              </Error>
            } 
            <SingleTile width="100">
              <SettingsName>
                Confirm Password <span className="important">*</span>
              </SettingsName>
              <StyledInput type="password" placeholder="Confirm Password" name="rePassword" value={rePassword} onChange={(e) => this.inputChangePass(e)}/>
            </SingleTile> 
            { showErrmsg 
              &&
              <Error>
                <ErrorMessage>{errMsg}</ErrorMessage> 
              </Error>
            }   
            {/* <SingleTile width="100">
              <SettingsName>
                Date of Birth <span className="important">*</span>
              </SettingsName>
              <InputContainer widthInput={1} marginTop="15px">
                <div onClick={(e) => this.closeDate(e)} ref={(node) => showDate === 'birthDate' && this.setDatePickerWrapper(node)}>
                  <CustomDateTime
                    open={showDate === 'birthDate'}
                    inputProps={{ placeholder: 'Pick Date Of Birth', readOnly: true}}
                    dateFormat="MM/DD/YYYY"
                    closeOnSelect={true}
                    closeOnTab={true}
                    timeFormat={false}
                    value={birthDate != '' && moment(birthDate).format('MM/DD/YYYY')}
                    isValidDate={valid}
                    viewDate={birthDate ? birthDate : yesterday}
                    onChange={(e) => this.changeDate(e, 'birthDate')}
                    onFocus={() => this.showDatePicker('birthDate') }
                    viewMode="years" 
                  />
                  <DateIconContainer onClick={() => this.showDatePicker('birthDate')}>
                    <DateIcon color="#999999" viewBox="0 0 16 17" />
                  </DateIconContainer>
                </div>
              </InputContainer>
            </SingleTile> */}
            <SubmitContainer>
              <SubmitButton 
                type="submit"
                float={1} 
                Align={1}  
                width= "150px" 
                radius="5px" 
                fontSize="13px" 
                bgColor= '#159fc9'
                disabled={!checkMail || rePassword == '' || password == '' }
              >
                Submit
              </SubmitButton>  
            </SubmitContainer>
          </form>
        </StyledBody>
      </StyledModal>
    );
  }
}

ResetPasswordModal.propTypes = {
  resetPassword: PropTypes.func,
  history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(resetPassword(data))
});

export default connect(null, mapDispatchToProps)(ResetPasswordModal);